import enumsEn from '@/locales/enum-en.json'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    getEnumList(names, ...lists) {
      let enums = enumsEn
      if (!(names instanceof Array)) {
        names = [names]
      }
      this.$Enums.getCollection({params: {name: names, cache: true, ttl: 86400}})
        .then(response => {
          names.forEach((name, index) => {
            response.data['hydra:member'].forEach(element => {
              if (name === element.name) {
                element.keys.forEach(key => {
                  lists[index].push({
                    label: enums[name][key],
                    id: key,
                  })
                })
              }
            })
          })
        })
    },
    getOrderStatuses(order, ...lists) {
      const enums = enumsEn
      const name = 'ORDER_STATUS';

      if (order.id && order.status) {
        lists[0].push({
          label: enums[name][order.status],
          id: order.status,
        })
      }

      this.$Enums.getCollection({params: {name: [name], cache: true, ttl: 86400}})
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              if (name === element.name) {
                element.keys.forEach(status => {
                  // ADD ORDER
                  if (typeof order.id === 'undefined' && status === 'NEW') {
                    lists[0].push({
                      label: enums[name][status],
                      id: status,
                    })
                  }

                  // EDIT ORDER
                  if (order.id && order.status) {
                    if (order.status === 'NEW' && status === 'PENDING') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                    if (order.status === 'PENDING' && status === 'APPROVED_BY_MANAGER') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                    if (order.status === 'APPROVED_BY_MANAGER' && status === 'SENT') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                    if (order.status === 'SENT' && status === 'WAITING_FOR_INVOICE') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                    if (order.status === 'WAITING_FOR_INVOICE' && status === 'INVOICE_PAID') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                    if (order.status === 'INVOICE_PAID' && status === 'DONE') {
                      lists[0].push({
                        label: enums[name][status],
                        id: status,
                      })
                    }
                  }
                })
              }
            })
          })
    },
    getEntityOptionList(types, ...lists) {
      if (!(types instanceof Array)) {
        types = [types]
      }
      this.$EntityOptions.getCollection({params: {pagination: false, type: types, cache: true, ttl: 300}})
        .then(response => {
          types.forEach((type, index) => {
            response.data['hydra:member'].forEach(element => {
              if (type === element.type) {
                lists[index].push({
                  label: element.name,
                  id: element['@id'],
                })
              }
            })
          })
        })
    },
    getUserList(args, ...lists) {
      const {params} = args

      params.pagination = false
      if (!(params.role instanceof Array)) {
        params.role = [params.role]
      }

      this.$Users.getCollection({params}, 'user_list')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            params.role.forEach((role, index) => {
              if (role === element.role) {
                let label = `${element.givenName} ${element.familyName}`
                if(role === "ROLE_CUSTOMER") {
                  label = element.customer
                }
                if (element.number) {
                  label = `${element.number} ${label}`
                }
                lists[index].push({
                  label,
                  id: element['@id'],
                  numberId: element.id,
                })
              }
            })
          })
        })
    },
    getSkillList(args, list) {
      const {params} = args

      params.pagination = false

      this.$Skills.getCollection({params}, 'skill_list')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
              list.push({
                label: `${element.typeName} - ${element.skillNo} ${element.skill}`,
                id: element['@id'],
                numberId: element.id,
            })
          })
        })
    },
    getItemList(args, ...lists) {
      const {params} = args

      params.pagination = false
      if (!(params.itemCategory instanceof Array)) {
        params.itemCategory = [params.itemCategory]
      }

      this.$Items.getCollection({params}, 'item_list')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            if (params.itemCategory && params.itemCategory.length > 0) {
              let name = [
                element.number,
                element.crossReference,
                element.name,
              ].join(' ').trim()
              params.itemCategory.forEach((itemCategory, index) => {
                if (itemCategory === element.itemCategory) {
                  lists[index].push({
                    label: name,
                    id: element['@id'],
                    numberId: element.id,
                    item: element,
                    fileUrl: element.file ? element.file.url : null,
                  })
                }
              })
            } else {
              lists.push({
                label: name,
                id: element['@id'],
                numberId: element.id,
                item: element,
                fileUrl: element.file ? element.file.url : null,
              })
            }
          })
        })
    },
    getResourceList(resource, list, params = {}) {
      params.pagination = false

      resource.getCollection({params}, 'resource_list')
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            list.push({
              label: `${element.name}`,
              id: element['@id'],
              numberId: element.id,
            })
          })
        })
    },
  },
}
