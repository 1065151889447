<template>
    <div class="content-box">
      <b-form @submit.prevent="onSubmit">

              <b-row>
                <b-col cols-lg="6">

                  <b-form-group
                    :class="{
                      row,
                      error: v$.skillTypeSelect.$errors.length,
                    }"
                    :label="$t('forms.skillType')"
                    label-for="skillTypeSelect"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <v-select
                      id="skillTypeSelect"
                      v-model="skillTypeSelect"
                      :options="skillTypes"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.skillTypeSelect.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>
                  <b-form-group
                    :class="{
                      row,
                      error: v$.categorySelect.$errors.length,
                    }"
                    :label="$t('forms.category')"
                    label-for="categorySelect"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <v-select
                      id="categorySelect"
                      v-model="categorySelect"
                      :options="categories"
                    />
                    <div
                      class="input-errors"
                      v-for="error of v$.categorySelect.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>
                  <b-form-group
                    :class="{ row, error: v$.skill.skillNo.$errors.length }"
                    :label="$t('forms.skillNo')"
                    label-for="skillNo"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <b-form-input v-model="skill.skillNo" id="skillNo" />
                    <div
                      class="input-errors"
                      v-for="error of v$.skill.skillNo.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>
                  <b-form-group
                    :class="{ row, error: v$.skill.skill.$errors.length }"
                    :label="$t('forms.skill')"
                    label-for="skill"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <b-form-input v-model="skill.skill" id="level" />
                    <div
                      class="input-errors"
                      v-for="error of v$.skill.skill.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>
                  <b-form-group
                    :class="{ row, error: v$.skill.skillHun.$errors.length }"
                    :label="$t('forms.skillHun')"
                    label-for="skillHun"
                    label-cols-md="4"
                    content-cols-lg="8"
                  >
                    <b-form-input v-model="skill.skillHun" id="level" />
                    <div
                      class="input-errors"
                      v-for="error of v$.skill.skillHun.$errors"
                      :key="error.$uid"
                    >
                      <small class="text-danger">{{ error.$message }}</small>
                    </div>
                  </b-form-group>

                  <b-row>
                    <b-col lg="4">
                      <label for="">{{ $t('forms.image') }}</label>
                    </b-col>
                    <b-col lg="8">
                      <image-upload :preview-image="fileURL" @fileUploaded="setPhoto"/>
                    </b-col>
                  </b-row>

                </b-col>
                <b-col cols-lg="6">

                </b-col>
              </b-row>

      </b-form>
    </div>
  </template>

  <script>
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import ResourceUtils from "@/mixins/ResourceUtils";
  import ListUtils from "@/mixins/ListUtils";
  import "vue3-date-time-picker/dist/main.css";
  import vSelect from "vue-select";
  import ImageUpload from "@/components/Inputs/ImageUpload.vue";

  export default {
    components: {
      vSelect,
      ImageUpload
    },
    props: {
      skillObject: { Object, required: true },
      action: { type: String, default: null },
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList('SKILL_CATEGORY', this.categories)
        this.getResourceList(this.$EntityOptions, this.skillTypes, {type: 'SKILL_TYPE'})
        this.skillTypeSelect = this.skill.skillTypeSelect
        this.categorySelect = this.skill.categorySelect
        if (this.skill.fileUrl) {
          this.fileURL = this.skill.fileUrl;
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        skillTypeSelect: { required },
        categorySelect: { required },
        skill: {
          skillNo: { required },
          skill: { required },
          skillHun: { required }
        },
      };
    },
    data() {
      return {
        skill: this.skillObject,
        skillTypeSelect: {},
        skillTypes: [],
        categories: [],
        categorySelect: {},
        fileURL: null
    };
    },
    watch: {
      action() {
        this.onSubmit();
      },
    },
    methods: {
        setPhoto(data) {
          if ('file' in this.skill) {
            this.deleteByUrl(this.$Files, this.skill.file, null);
          }
          this.skill.file = data["@id"];
          this.fileURL = data.url;
          const body = {
            file: this.skill.file
          }
          this.update(this.$Skills, this.skill.id, body, this.$t('messages.employeeUpdated'));
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                const body = {
                    skill: this.skill.skill,
                    skillHun: this.skill.skillHun,
                    skillNo: this.skill.skillNo,
                    type: this.skillTypeSelect.id,
                    category: this.categorySelect.id,
                };
                if (this.action === 'add') {
                    const userData = JSON.parse(localStorage.getItem("userData"));
                    body.user = userData['@id']
                }
                if (this.action) {
                    this.action === "add"
                        ? this.create(
                            this.$Skills,
                            body,
                            this.$t("messages.skillCreated"),
                            null,
                            this.success,
                            this.error
                        )
                        : this.update(
                            this.$Skills,
                            this.skill.id,
                            body,
                            this.$t("messages.skillUpdated"),
                            null,
                            null,
                            this.error
                        );
                    this.$emit("clearAction");
                }
            } else {
                this.$emit("clearAction");
            }
        },
      success() {
        this.$router.push(`/skills`);
      },
      error() {
        this.$emit("clearAction");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  </style>

